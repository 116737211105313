import React, { useEffect, useState } from "react";
import Snowfall from "react-snowfall";
import present from "./present.png";
import logo from "./logo.png";
import sponsor1 from "./sponsor1.png";
import sponsor2 from "./sponsor2.png";
import sponsor3 from "./sponsor3.png";
import sponsor4 from "./sponsor4.png";
import sponsor5 from "./sponsor5.png";
import sponsor6 from "./sponsor6.png";
import sponsor7 from "./sponsor7.png";
import sponsor8 from "./sponsor8.png";
import "./App.css";
import { useCountdown } from "./useCountdown";

// update 13.12 2.0

const App = () => {
  const [status, setStatus] = useState([]);

  const fetchStatus = async () => {
    try {
        const response = await fetch("https://diables.pl/api/status");
      if (!response.ok) {
        console.error("Błąd odpowiedzi z serwera:", response.status);
        return;
      }
      const data = await response.json();
      setStatus(data);
    } catch (error) {
      console.error("Błąd podczas pobierania danych z backendu:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setDate(date.getDate() + 1);
    const timeout = date.getTime()-new Date().getTime();
    setTimeout(fetchStatus, timeout);
  }, []);

  

const ShowCounter = ({day, rainbow}) => {
    const date = new Date();
    date.setDate(day);
    date.setHours(0, 0, 0, 0);
    date.setMonth(11);
    const [days, hours, minutes, seconds] = useCountdown(date);
    return (
        <div className="day-content">
            <img
                src={present}
                alt="Present"
                className="present-icon"
            />
            <div className="day-info">
                <b>Dzień {day}</b>
                <div className="available">Dostępne za:</div>
                <div>

                    {String(days).padStart(2, "0")}d{" "}
                    {String(hours).padStart(2, "0")}h{" "}
                    {String(minutes).padStart(2, "0")}m{" "}
                    {String(seconds).padStart(2, "0")}s
                </div>
            </div>
        </div>
    );
};

    return (
        <div className="calendar-container">
            {/* Śnieg na całej stronie */}
            <Snowfall
                color="#ffffff" // Kolor płatków
                snowflakeCount={100} // Liczba płatków śniegu
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%", // Pokrywa całą wysokość strony
                    zIndex: -5, // Tło za całą aplikacją
                }}
            />
            <header className="header">
                <img src={logo} alt="Logo" className="logo"/>
        <p className="sponsor-message">Nasi sponsorzy</p>
        <div className="sponsor-logos">
          <img src={sponsor1} alt="Sponsor 1" className="sponsor-logo" />
          <img src={sponsor2} alt="Sponsor 2" className="sponsor-logo" />
          <img src={sponsor3} alt="Sponsor 3" className="sponsor-logo" />
          <img src={sponsor4} alt="Sponsor 4" className="sponsor-logo" />
          <img src={sponsor5} alt="Sponsor 5" className="sponsor-logo" />
          <img src={sponsor6} alt="Sponsor 5" className="sponsor-logo" />
          <img src={sponsor7} alt="Sponsor 5" className="sponsor-logo" />
          <img src={sponsor8} alt="Sponsor 5" className="sponsor-logo" />
        </div>
        <p className="desc">Weź udział w super mikołajkach, wpadaj tu codziennie, aby zobaczyć, co dzisiaj można zgarnąć!</p>
      </header>

      <p className="welcome-message">Kalendarz Adwentowy</p>
      <p className="desc"><strong>Rozdania trwają od 00:01 do 23:59 każdego dnia!</strong></p>
      <p className="desc">Pamiętaj aby codziennie wziąć udział w nowym rozdaniu</p>
      <div className="calendar-grid">
  {status.map(({ day, unlocked, link, rainbow, expired, isToday }) => (
    <div
      key={day}
      className={`day-card 
        ${isToday ? "today" : ""} 
        ${expired ? "expired" : ""} 
        ${unlocked ? "unlocked" : "locked"} 
        ${rainbow ? "rainbow-effect" : ""}`}
    >
      {unlocked && !expired ? (
        // Dzień odblokowany i aktywny
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="day-link"
        >
          <div className="day-content">
            <img src={present} alt="Present" className="present-icon" />
            <div className="day-info">
              <b>Dzień {day}</b>
              <div>Kliknij, aby odkryć!</div>
            </div>
          </div>
        </a>
      ) : expired ? (
        // Dzień wygasły z obrazkiem prezentu
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="day-link"
        >
          <div className="day-content expired-content">
            <img
              src={present} // Obrazek prezentu
              alt={`Wygasły dzień ${day}`}
              className="present-icon"
            />
            <div className="day-info">
              <b>Dzień {day}</b>
              <div>To rozdanie już wygasło</div>
            </div>
          </div>
        </a>
      ) : (
        // Dzień z licznikiem (jeszcze niedostępny)
        <ShowCounter day={day} />
      )}
    </div>
  ))}
</div>

      <br></br>
      <p className="desc"><strong>Strona stworzona przez Osci5</strong></p>
      <p className="desc">Design by Smaczny, Hosted by Craftserve</p>
      <p className="desc2">Aby odebrać nagrodę w postaci "Monster Energy Zgrzewka" należy być pełnoletni - będzie to weryfikowane</p>
    </div>
  );
};

export default App;
